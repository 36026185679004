<template>
  <!-- 账号管理页面，实际是员工管理 -->
  <div class="hk_page_routerview">
    <div class="hk_page_title">账号管理
      <div class="hk_page_title_right">
        <el-button size="small" type="primary" plain @click="goEditFun(null)">添加账号</el-button>
      </div>
    </div>
    <div class="hk_page_wrap">
      <div class="hk-common-page">
        <div class="hk_search_box">
          <div class="hk_search_box_item">
            员工姓名：
            <el-input debounce size="small" @keyup.13.native="searchEmployeeFun" clearable placeholder="搜索员工姓名" v-model="searchName">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="hk_search_box_item">
            手机号：
            <el-input debounce size="small" @keyup.13.native="searchEmployeeFun" clearable placeholder="全拼手机号或后四位" class="hk-ipt-number" v-model="searchPhone">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="hk_search_box_item">
            账号角色：
            <el-select size="small" v-model="searchRoleIdList" placeholder="请选择（支持多选）" clearable multiple collapse-tags>
              <el-option :label="item.roleName" :value="item.roleId" :key="item.id" v-for="item in roleList">{{ item.roleName }}</el-option>
            </el-select>
          </div>
          <div class="hk_search_box_item">
            校区（多选）：
            <el-select size="small" v-model="searchZoneList" placeholder="请选择（支持多选）" clearable multiple collapse-tags>
              <el-option :label="txt" :value="key" :key="key" v-for="(txt,key) in schoolZoneObj">{{ txt}}</el-option>
            </el-select>

          </div>
          <div class="hk_search_box_item">
            <el-button type="primary" size="small" @click="searchEmployeeFun">搜索</el-button>
          </div>
        </div>
        <div class="hk_result_total_box">
          搜索共有 <span class="hk-font-highlight">{{ totalNum }}</span> 个账号
        </div>

        <div class="hk_custom_table">
          <el-table ref="employeeTable" :data="tableData" style="width: 100%">
            <el-table-column label="员工ID" width="80px">
              <template slot-scope="scope">
                <span>{{scope.row.id}}</span>
              </template>
            </el-table-column>

            <el-table-column label="classIn姓名" width="140px">
              <template slot-scope="scope">
                <span>{{scope.row.staffName}}</span>
              </template>
            </el-table-column>

            <el-table-column label="classIn手机号" width="130px">
              <template slot-scope="scope">
                <span>{{scope.row.phone}}</span>
              </template>
            </el-table-column>
            <el-table-column label="绑定企微" width="180px">
              <template slot-scope="scope">
                <span>{{scope.row.nickname?scope.row.nickname+'@拾伍营销' : '-'}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="学科" width="120px">
              <template slot-scope="scope">
                <span>{{filterSubjectType(scope.row.subjectType)}}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="校区" :min-width="130">
              <template slot-scope="scope">
                <span>{{scope.row.schoolZoneStr}}</span>
              </template>
            </el-table-column>
            <el-table-column label="毕业院校" width="140px">
              <template slot-scope="scope">
                <span>{{scope.row.college || '-'}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="邮箱" width="140px">
              <template slot-scope="scope">
                <span>{{scope.row.email||'-'}}</span>
              </template>
            </el-table-column> -->
            <el-table-column label="账号角色" width="140px">
              <template slot-scope="scope">
                <span v-for="(item,idx) in scope.row.roleList" :key="idx">{{ item.roleName }}；</span>
              </template>
            </el-table-column>
            <el-table-column label="账号状态" width="120px">
              <template slot-scope="scope">
                <span class="account-status" :class="'account-status_'+scope.row.acStatus"></span> {{scope.row.acStatusStr}}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120px" fixed="right">
              <template slot-scope="scope">
                <span class="hk-table-option hk-table-option-first" @click="goEditFun(scope.row.id)">编辑</span>
                <template v-if="scope.row.acStatus=='NORMAL'">
                  <el-popover placement="top-end" width="280" trigger="click" :ref="'popover_stop_'+ scope.row.id ">
                    <p class="popover-option"><i class="el-icon-warning hk-font-highlight"></i>账号停用后，用户将无法登录，确认停用吗？</p>
                    <div style="text-align: right; margin: 20px 0 0">
                      <el-button size="mini" @click="cancelStopFun(scope.row)">取消</el-button>
                      <el-button type="primary" size="mini" @click="confirmStopFun(scope.row,'STOP')">停用</el-button>
                    </div>
                    <span class="hk-table-option" slot="reference">停用</span>
                  </el-popover>
                </template>
                <template v-if="scope.row.acStatus!=='NORMAL'">
                  <el-popover placement="top-end" width="280" trigger="click" :ref="'popover_again_'+ scope.row.id ">
                    <p class="popover-option"><i class="el-icon-warning hk-font-highlight"></i>确认重新启用该账号？</p>
                    <div style="text-align: right; margin: 20px 0 0">
                      <el-button size="mini" @click="cancelAgainFun(scope.row)">取消</el-button>
                      <el-button type="primary" size="mini" @click="confirmStopFun(scope.row,'NORMAL')">重新启用</el-button>
                    </div>
                    <span class="hk-table-option" slot="reference">启用</span>
                  </el-popover>
                </template>
              </template>
            </el-table-column>
            <div slot="empty">
              <p>没有你要找的账号哦！</p>
            </div>

          </el-table>
          <div class="hk_pagination" v-if="totalNum>0">
            <el-pagination @current-change="handleCurPageChange" @size-change="handleSizeChange" :current-page.sync="curPage" :page-size="pageSize" :page-sizes="[10,20,30,50]" layout="total,sizes,prev, pager, next" :total="totalNum">
            </el-pagination>

          </div>
        </div>
        <div class="hk_custom_scroll">
          <div ref="hkCustomScroll" class="hk-scroll" @scroll="handleScrollTop">
            <div class="hk-scroll-content" :style="'width:'+tableWidth+'px'"></div>
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { SubjectTypeEnum, SchoolZoneEnum } from '../../utils/enums';

// export SubjectTypeEnum
export default {
  data() {
    return {
      tableWidth: 0, //自定义滚动条的内容宽度

      searchName: '', //搜索条件--姓名
      searchPhone: '', //搜索条件--手机号
      searchRoleIdList: [], //搜索条件--角色
      searchZoneList: [],//搜索条件--校区列表

      tableData: [], //员工列表中显示的数据(搜索后,只显示符合条件的员工)
      pageSize: 30, //每页10条数据
      curPage: 1, //当前第几页
      totalNum: 0, //总共几条数据

      ifShowAdd: false, //true-显示添加弹窗；false-不显示
      editId: '', //编辑员工时的id
      addForm: {
        "staffName": "",
        "phone": "",
        "subjectType": "", //学科，单选
        "roleIdList": [],
        "staffFeeList": [] //拼装而成的数据
      },
      addRules: {},
      roleList: [],//用到的角色列表下拉框
      // gradeList: [],//年级列表
      subjectTypeObj: SubjectTypeEnum, //学科列表
      schoolZoneObj: SchoolZoneEnum, //校区列表
    }
  },
  mounted() {
    this.getAllRole() //获取角色列表
    // this.getAllGrade()
    this.getEmployeeListFun()
  },
  methods: {
    filterSubjectType(key) {
      let txt = '--'
      if (key) {
        txt = this.subjectTypeObj[key]
      }
      return txt
    },

    searchEmployeeFun() {
      this.curPage = 1 //从第一页开始
      this.tableData = [];
      this.totalNum = 0
      this.getEmployeeListFun()

    },

    handleScrollTop(e) {
      let that = this;
      let tmpLeft = e.target.scrollLeft
      if (that.$refs["employeeTable"]) {
        that.tableDom = that.$refs["employeeTable"].bodyWrapper
        that.tableDom.scrollTo(tmpLeft, 0)
      }
    },

    //获取员工列表
    getEmployeeListFun() {
      let that = this;
      let param = {
        pn: that.curPage,
        ps: that.pageSize,

      };
      if (that.searchName && that.searchName.trim().length > 0) {
        param.staffName = that.searchName.trim()
      }
      if (that.searchPhone) {
        param.phone = that.searchPhone
      }
      console.log(that.searchRoleIdList, '----==zonelist===--', that.searchZoneList)
      if (that.searchRoleIdList.length) {
        param.roleIdList = that.searchRoleIdList
      }
      if (that.searchZoneList.length) {
        param.schoolZoneList = that.searchZoneList
      }
      that.request({
        method: 'post',
        url: '/manage/staff/listStaff',
        param: param,
        success: function (result) {
          if (result.code == 200) {
            let data = result.result;
            that.tableData = data.list;
            that.totalNum = Number(data.total);

            setTimeout(() => {
              if (that.$refs["employeeTable"]) {
                that.tableWidth = that.$refs["employeeTable"].bodyWrapper.scrollWidth + 50 //没有fixed或者fixed=right,发现宽度少了
                that.$refs["employeeTable"].doLayout()
                that.tableDom = that.$refs["employeeTable"].bodyWrapper
                that.tableDom.addEventListener('scroll', () => {
                  let scrollLeft = that.tableDom.scrollLeft
                  // console.log('table scroll----', scrollLeft)
                  that.$refs.hkCustomScroll.scrollTo(scrollLeft, 0)
                })
              }
            }, 300)

          } else {
            console.log(result, '----not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取员工失败。')
          console.log(error, '-----fenye 获取员工失败')
        }
      })
    },
    //分页
    handleCurPageChange(val) {
      console.log(`当前页: ${val}`);
      this.curPage = val
      this.getEmployeeListFun();
    },
    //修改每页展示的记录个数
    handleSizeChange(val) {
      console.log(`每页显示: ${val} 条数据`);
      this.pageSize = val
      this.curPage = 1
      this.getEmployeeListFun();
    },

    //停用 或 启用 员工
    confirmStopFun(row, newStatus) {
      let that = this;

      let params = { id: row.id, acStatus: newStatus };
      that.request({
        method: 'post',
        url: '/manage/staff/updateAcStatus',
        param: params,
        success: function (result) {
          if (result.code == 200) {
            // that.$message.success('操作成功！')
            that.getEmployeeListFun()
            if (newStatus == 'NORMAL') { //启用
              that.$message.success('启用成功！')
              that.cancelAgainFun(row)
            } else { //停用
              that.$message.success('停用成功！')
              that.cancelStopFun(row)
            }
          } else {
            that.$message.error('操作失败，' + result.message)
          }
        },
        error: function (error) {
          //服务器异常
          console.log(error)
          that.$message.error('删除失败了。')
        }
      })

    },

    //取消停用员工
    cancelStopFun(row) {
      this.$refs['popover_stop_' + row.id].popperElm.style.display = "none"
    },


    //取消启用
    cancelAgainFun(row) {
      this.$refs['popover_again_' + row.id].popperElm.style.display = "none"
    },

    //添加账号 OR 列表点击编辑
    goEditFun(id) {
      let that = this;
      let url = '/addemployee'
      console.log(id, '------id===goEditFun')
      if (id) {
        url = '/addemployee?id=' + id
      }
      // console.log(url)
      that.$router.push(url)
    },

    //获取角色列表
    getAllRole() {
      let that = this;
      that.request({
        method: 'post',
        url: '/manage/staff/listRole',
        param: {},
        success: function (result) {
          if (result.code == 200) {
            that.roleList = result.result || [];
          } else {
            console.log(result, '----角色 not 200')
          }
        },
        error: function (error) {
          //服务器异常
          that.$message.error('获取角色失败。')
        }
      })
    },
    // 获取年级列表 gradeList
    // getAllGrade() {
    //   let that = this;
    //   that.request({
    //     method: 'post',
    //     url: '/manage/base/listGrade',
    //     param: {},
    //     success: function (result) {
    //       if (result.code == 200) {
    //         that.gradeList = result.result || [];
    //       } else {
    //         console.log(result, '----年级列表 not 200')
    //       }
    //     },
    //     error: function (error) {
    //       //服务器异常
    //       that.$message.error('获取年级列表失败。')
    //     }
    //   })
    // },

  }
}
</script>

<style lang="scss" scoped>
.hk_custom_table {
  ::v-deep .el-table--scrollable-x .el-table__body-wrapper {
    overflow-x: hidden !important;
  }
  //将el-table的横向滚动条给隐藏,只能写在每个页面，写在公共里面无效
}

.account-status {
  width: 4px;
  height: 4px;
  background: #999;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}
.account-status_NORMAL {
  background: #52c41a;
}
.account-status_STOP {
  background: #db3843;
}
</style>